import React from 'react';
import { css } from '@emotion/core';
import { Link, graphql } from 'gatsby';
import { MdxArticle, SanityPost } from '../../types/Article';
import Content from '../../layouts/main';
import styled from '../../styled';
import { SEO } from '../../components/SEO';
import {
  FeaturedPostLink,
  PostLink,
  SanityPostLink
} from '../../components/PostLink';
import { Tag } from '../../types/Tag';
import { AffiliateProgramList } from '../../types/AffiliateProgramList';

const Heading = styled('span')`
  display: inline-block;
  color: #4f4f4f;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
`;

const PostContainer = styled('div')`
  ${({ theme }) => css`
    max-width: 1200px;
    padding: ${theme.spacing(2)}px;
    margin: ${theme.spacing(10)}px auto;
  `};
`;

type PageQueryData = {
  allMdx: {
    edges: {
      node: MdxArticle;
    }[];
  };
  allSanityAffiliateNiche: {
    edges: {
      node: AffiliateProgramList;
    }[];
  };
  allSanityPost: {
    edges: {
      node: SanityPost;
    }[];
  };
  allTagYaml: {
    edges: {
      node: Tag;
    }[];
  };
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(8)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;
  margin-bottom: ${(p) => p.theme.spacing(8)}px;

  & > div {
    margin-bottom: 12px;
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  a {
    color: #767e87;
  }
`;

const IndexPage = ({
  data: {
    allMdx: { edges },
    allTagYaml,
    allSanityPost,
    allSanityAffiliateNiche
  }
}: {
  data: PageQueryData;
}) => {
  const livePosts = edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map((edge) => edge.node);

  const sanityPosts = allSanityPost.edges
    .filter((e) => !!e.node.publishedAt)
    .map((e) => e.node);

  const sanityAffiliateNiches = allSanityAffiliateNiche.edges
    .filter((e) => !!e.node.publishedAt)
    .map((e) => e.node);

  const featuredPosts = sanityPosts.filter((p) => p.featured);

  const sanityAffiliateTipsPosts = sanityPosts.filter(
    (p) =>
      p.categories.find((c) => c.slug.current === 'affiliate-tips') &&
      !p.featured
  );

  const productUpdatePosts = livePosts.filter(
    (p) => p.frontmatter.category === 'product-updates'
  );

  return (
    <Content>
      <>
        <SEO
          title="Affiliate Marketing Blog"
          description="Keep up with the latest news on how to improve your affiliate marketing income with data-driven strategies."
          pathname="/blog/"
        />
        <PostContainer>
          <HeadingWrapper>
            <Heading>Featured Article</Heading>
          </HeadingWrapper>
          <FeaturedPostLink post={featuredPosts[0]} />
          <br />
          <br />
          <br />
          <HeadingWrapper>
            <Heading>Affiliate Tips & Tutorials</Heading>
          </HeadingWrapper>
          <Grid>
            {sanityAffiliateTipsPosts.map((post) => (
              <SanityPostLink key={post.slug.current} post={post} />
            ))}
          </Grid>
          <HeadingWrapper>
            <Heading>Affiliate Program Guides</Heading>
          </HeadingWrapper>
          <Grid>
            {sanityAffiliateNiches.map((niche) => (
              <SanityPostLink post={niche} key={niche.slug.current} />
            ))}
          </Grid>
          <HeadingWrapper>
            <Heading>Product Updates</Heading>
            <Link to="/category/product-updates/">See all updates</Link>
          </HeadingWrapper>
          <Grid>
            {productUpdatePosts.slice(0, 3).map((post) => (
              <PostLink key={post.fields.slug} post={post} />
            ))}
          </Grid>
        </PostContainer>
      </>
    </Content>
  );
};

export const pageQuery = graphql`
  query {
    allTagYaml {
      edges {
        node {
          name
          slug
        }
      }
    }
    allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          publishedAt(formatString: "MMMM DD, YYYY")
          lastUpdated(formatString: "MMMM DD, YYYY")
          featured
          categories {
            slug {
              current
            }
          }
          mainImage {
            image {
              src
            }
          }
          title
          description
          slug {
            current
          }
        }
      }
    }
    allSanityAffiliateNiche(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          title
          description
          slug {
            current
          }
          mainImage {
            alt
            image {
              src
              width
              height
            }
          }
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            summary
            image
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
